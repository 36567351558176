<template>
  <button @click="buttonAction" class="global-button">
    <div class="global-button-content-wrapper">
      <div v-if="icon" class="global-button-icon-wrapper">
        <icon :icon="icon" />
      </div>
      <div class="global-button-label-wrapper">{{ label }}</div>
    </div>
  </button>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  components: { Icon: defineAsyncComponent(() => import("./Icon.vue")) },
  name: "Button",
  props: {
    label: {
      required: true,
      type: String
    },
    action: {
      required: false,
      type: Function,
      default: null
    },
    icon: {
      required: false,
      type: String
    }
  },
  methods: {
    buttonAction() {
      if (this.action) {
        this.action();
      } else {
        this.$emit("buttonPressed");
      }
    }
  }
};
</script>
